import React, {useEffect, useRef} from "react";
import Plyr from "plyr";

const VideoPlayerPlur = ({src}) => {
	const videoRef = useRef(null); // Ссылка на элемент video

	// Инициализация Plyr
	useEffect(() => {
		const player = new Plyr(videoRef.current, {
			controls: [
				"play-large", // Большая кнопка воспроизведения
				"play", // Кнопка воспроизведения/паузы
				"progress", // Прогресс-бар
				"current-time", // Текущее время
				"fullscreen",
			],
			fullscreen: { enabled: true, fallback: true, iosNative: true },
		});
		player.on('enterfullscreen', (event) => {
			const wheelScrolled = new CustomEvent("enterfullscreen");
			window.dispatchEvent(wheelScrolled);
		});

		player.on('exitfullscreen', (event) => {
			const wheelScrolled = new CustomEvent("exitfullscreen");
			window.dispatchEvent(wheelScrolled);
		});
		
		// Очистка при размонтировании компонента
		return () => {
			player.destroy();
		};
	}, []);

	return (
		<div className="video-player">
			<video ref={videoRef} controls poster='/img/prew.jpg'>
				<source src={src} type="video/mp4"/>
				Ваш браузер не поддерживает видео.
			</video>
		</div>
	);
};

export default VideoPlayerPlur;