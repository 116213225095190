import React, {useEffect, useRef, useState} from "react";
import { Navigation } from "./navigation";
import { LightParalax } from "./paralaxElements/Light";
import { ShowflakeParalax } from "./paralaxElements/Snowflakes";
import { Contact } from "./contact";
import { TreeWithLigthGroup } from "./paralaxElements/Tree1Svg";

export const Page = ({
	lightCount,
	showflakeCount,
	needNavBlock = true,
	needContactBlock = true,
	paralaxScrollType = "scroll",
	children,
}) => {
	const ref = useRef(null);
	const scrollContainerRef = useRef(null);
	const [_needNavBlock, setneedNavBlock] = useState(needNavBlock);
	useEffect(() => {
		window.addEventListener("wheel", (e) => {
			const wheelScrolled = new CustomEvent("wheelScrolled", {
				detail: {
					offset: scrollContainerRef.current.scrollTop,
				},
			});
			window.dispatchEvent(wheelScrolled);
		});
		window.addEventListener("enterfullscreen", (e) => {
			setneedNavBlock(false);
		});
		window.addEventListener("exitfullscreen", (e) => {
			setneedNavBlock(true);
		});
	}, []);
	return (
		<div id="paralax-container" ref={ref}>
			<div className="x5-content">
				<div className='x5-scroll-container' ref={scrollContainerRef}>
					{_needNavBlock && <Navigation />}
					{children}
					{needContactBlock && <Contact />}
				</div>
			</div>
			<LightParalax
				containerRef={ref}
				count={lightCount}
				type={paralaxScrollType}
			></LightParalax>
			<ShowflakeParalax
				containerRef={ref}
				count={showflakeCount}
				type={paralaxScrollType}
			></ShowflakeParalax>
			<div className="mobile-hide">
				<TreeWithLigthGroup></TreeWithLigthGroup>
			</div>
		</div>
	);
};
