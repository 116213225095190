import React from "react";
import {X5Countdown} from "./countdown/Countdown";

export const Header = (props) => {
	return (
		<div className="intro">
			<div className="col intro-text">
			<h1>Мечтаграм</h1>
			{/*<h2 className="intro-text-visible-desc">Х5 Group и фонд «Выручаем» <br></br>исполнили мечты!</h2>*/}
			<h2>Х5 Group исполнил мечты!</h2>
				{/*<div className="underHeader">*/}
				{/*	<p className="x5Text">X5 Group исполнит мечту!</p>*/}
				{/*</div>*/}
				<div className="x5-countdown-container x5-countdown-container--desktop">
					<X5Countdown size={'x5-countdown--desktop'} itemSize={70}></X5Countdown>
				</div>
				<div className="x5-countdown-container x5-countdown-container--tablet">
					<X5Countdown size={'x5-countdown--tablet'} itemSize={50}></X5Countdown>
				</div>
				<div className="x5-countdown-container x5-countdown-container--mobile">
					<X5Countdown size={'x5-countdown--mobile'} itemSize={32}></X5Countdown>
				</div>
				<div className="x5-countdown-container x5-countdown-container--mobile-small">
					<X5Countdown size={'x5-countdown--mobile-small'} itemSize={18}></X5Countdown>
				</div>
			</div>
		</div>
	);
};
