import React, {useEffect, useRef, useState} from 'react';
import VideoPlayerPlur from "./Plur";

const VideoPlayer = () => {
	const videoRef = useRef(null);
	const [fullscreen, setFullscreen] = useState(true);


	const handlePlay = () => {
		if (videoRef.current) {
			videoRef.current.play();
		}
	};

	return (
		<div className="video-container">
			<VideoPlayerPlur src="/img/Розыгрыш.mp4"/>
		</div>
	);
};

export default VideoPlayer;
